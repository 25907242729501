import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-market-vane/"
            }}>{`Market Vane`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-lethargic-asset-allocation/"
            }}>{`Keller's Lethargic Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: strategy based on macro-economic indicators`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking S&P 500 industry sectors`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 80% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $3,000`}</li>
      </ul>
      <p>{`Francois Soto is `}<em parentName="p">{`Factor-Based Asset Management`}</em>{`'s president and portfolio manager. He published multiple articles describing his sector rotation strategy on `}<em parentName="p">{`Seeking Alpha`}</em>{` between December 2020 and June 2021.`}</p>
      <p>{`Soto's `}<em parentName="p">{`Sector Rotation`}</em>{` strategy aims to improve returns and reduce drawdowns compared to holding the S&P 500. To achieve this goal, the strategy rotates its holdings of sector ETFs based on a mechanism that uses macro-economic indicators to date the economic cycle. This methodology should appeal to more conservative investors with a preference toward fundamental analysis and concerns regarding data-snooping bias or curve-fitting in conjunction with technical analysis.`}</p>
      <p>{`The `}<em parentName="p">{`Sector Rotation`}</em>{` strategy has low maintenance requirements, as it rebalances monthly and holds no more than three ETFs in equal-sized tranches.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Source `}</strong></p>
        <ul>
          <li parentName="ul">{`Seeking Alpha articles dated `}<a parentName="li" {...{
              "href": "https://seekingalpha.com/article/4394646-this-sector-rotation-strategy-made-17-percent-year-since-1991"
            }}>{`12/2020`}</a>{`, `}<a parentName="li" {...{
              "href": "https://seekingalpha.com/article/4431639-this-sector-rotation-strategy-made-17-percent-each-year-since-1991-part-2"
            }}>{`05/2021`}</a>{`, and `}<a parentName="li" {...{
              "href": "https://seekingalpha.com/article/4434713-sector-rotation-strategy-using-the-high-yield-spread"
            }}>{`06/2021`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://seekingalpha.com/author/francois-soto-cfa"
            }}>{`Francois Soto`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://factorbased.com/"
            }}>{`Factor-Based`}</a></li>
        </ul>
        <p><strong parentName="p">{` See Also `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://fred.stlouisfed.org/series/DBAA"
            }}>{`Seasoned BAA Corporate Bond Yield`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://fred.stlouisfed.org/series/DGS10"
            }}>{`10-Year Treasury Constant Maturity Rate`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/Soto_SectorRotation.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of Soto's `}<em parentName="p">{`Sector Rotation`}</em>{` strategy can be described as follows:`}</p>
      <ul>
        <li parentName="ul">{`calculate the yield-spread between U.S. Treasuries with ten years maturity and U.S. Corporate bonds with BAA rating`}</li>
        <li parentName="ul">{`date the economic cycle based on the yield-spread`}</li>
        <li parentName="ul">{`switch between four fixed asset allocations based on the economic phase`}</li>
      </ul>
      <p>{`We admire this unique approach to tactical asset allocation. Because its mechanism is rooted in macro-economic indicators, Soto's `}<em parentName="p">{`Sector Rotation`}</em>{` is closer to fundamental analysis than the technical analysis used by typical trading strategies. Consequently, Soto's strategy is a welcome addition to meta-portfolios that employ strategies based on technical analysis.`}</p>
      <p>{`Our strategy implementation follows Soto's articles as closely as possible, particularly the article using the high-yield spread published in June 2021. However, the article lacks detail, especially regarding the detection of rising and falling trends. As a result, our implementation is very similar to Soto's work but most likely not identical.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Soto's `}<em parentName="p">{`Sector Rotation`}</em>{` invests 100% of the available capital in the stock market and is not diversified across asset classes. Moreover, because the strategy focuses on three or less industry sectors, it can be considered even less diversified than the S&P 500. As a rule of thumb, investors should assume a tail risk similar to holding the index. The standard deviation of returns supports this assumption.`}</p>
      <p>{`As a result, we see Soto's strategy only as one component of a portfolio. It should pair equally well with other asset classes, e.g., bonds, or with trading strategies based on different mechanisms.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`The Monte-Carlo simulation shows that the `}<em parentName="p">{`Sector Rotation`}</em>{` achieves an upside over the S&P 500 while at the same time reducing the downside. The rolling returns show that the strategy slowly but continually gains over its benchmark and rarely trails behind for extended periods.`}</p>
      <p>{`At the same time, Soto's strategy does not do much to reduce portfolio volatility compared to the S&P 500. As a result, the `}<em parentName="p">{`Sector Rotation`}</em>{` shows higher volatility and lower risk-adjusted returns than strategies that either use more asset classes or trade more frequently.`}</p>
      <p>{`While implementing the strategy, we found that the strategy may flip-flop between economic phases while the high-yield spread is hovering around a constant level. This issue leads to excessive trades and underperformance in these years. Most likely, this could be improved by considering the asset price action for the dating of the economic cycle, much like Keller has demonstrated with his `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{`.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a059031a70f516aaffd03f010a24ae33/21b4d/backtest-1990.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACYElEQVQoz22TW0tUURzF94t9h7CHEDQjstf6AH2ELpCWmhJ2ealEMxXsIQj8AsEYSA9dSKIgTQrCCkRNc8YZy5k5o0fn5jgzx7mcy5zLWnHODDZJC36w92b9F///Zm+RyStIp9MoFIpwHIe2baMOOg5o1TAtm6QDEnTJl01+8O/j3qskuyZkpHMlCNu2WCe4VtuBtziorCO5b/FjsISLT+M8PhCl6A5BXF5jY/8vlDQDwjRNz+jKDaoVHkhRbb75UeQVX5ztvgQb74cprgUprvopelbZcCsAcXMZLWNrzGQLXqDXTS2Mlg2+9ed5YULiuScRto5uUHSuUnSvVLmxyoY7P3nktt8NY0NfEOJ6CC0PI9zNKhC29XfkJbnMs+PrFO2LFB3LFJ0LFF3LFL2BKn1LFL1ueICiK1TFHbljjcf6f6OkVShUw+BctMDHsym2PQrz6N0ITw/LbBuReWZMYutIlE2DUZ4YivHUqMSTwxJbhmJsGpTY/CDG5iEJTQMxnh+Xq3f4XU5xaj3Jl/4Uv8lZfpITfB2O8bl/m76vBU4HylzYKnE6kuLkvMKplQJno7tcTGf4RU5zTt7Du3CCM5EkKqYJMRPZQSCXRTCzj2yljFAhjc/JTfjWg+h5IWFiPoNNbQ/zuwlcmtzAM38MS/kdKHYJkprFdrmIueQO3ktbNDUNwvHe1v+1aSUZr+QO9ou5OFWq//WCDhVFgdA0DbphQNN1D93QoeoadN2Aoha9tabqUDUNaTXrnes1X32dpumoVCoQjuPg0O/wsGwLju14GIYBy7IAG//6DtW6jf4BJiHvKg5PTX8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Soto's Sector Rotation",
              "title": "Soto's Sector Rotation",
              "src": "/static/a059031a70f516aaffd03f010a24ae33/c1b63/backtest-1990.png",
              "srcSet": ["/static/a059031a70f516aaffd03f010a24ae33/5a46d/backtest-1990.png 300w", "/static/a059031a70f516aaffd03f010a24ae33/0a47e/backtest-1990.png 600w", "/static/a059031a70f516aaffd03f010a24ae33/c1b63/backtest-1990.png 1200w", "/static/a059031a70f516aaffd03f010a24ae33/21b4d/backtest-1990.png 1280w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`We tested the strategy back to early 1990 to examine its performance over two complete economic cycles. Over this period spanning more than 30 years, the strategy was able to keep up with and beat the S&P 500. During the recessions of 2000 and 2008, the strategy significantly reduced the drawdowns and shortened the recovery time. In 2020, the strategy showed similar drawdowns as the S&P 500 but recovered slightly slower.`}</p>
      <p>{`In summary, we like the strategy's orthogonality to strategies based on technical analysis. Further, we find the `}<em parentName="p">{`Sector Rotation`}</em>{` performs admirably well, especially given its simple construction.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`The `}<em parentName="p">{`Sector Rotation`}</em>{` trades infrequently. However, often assets will not be held long enough to qualify for long-term treatment of capital gains. Consequently, the strategy performs best in tax-deferred accounts. As the `}<em parentName="p">{`Sector Rotation`}</em>{` does not have a substantial upside over the S&P 500, the Soto's strategy is likely not a good fit for taxable accounts.`}</p>
      <p>{`The `}<em parentName="p">{`Sector Rotation`}</em>{` holds between one and three ETFs with equally-sized positions. Therefore, the strategy can work as intended with accounts of $3,000 or more.`}</p>
    </Review>
    <Notes mdxType="Notes">
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      